import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/ucenter/user'
export default {

  save(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  getUserPage(data) {
    return axios.post(`${prefixPath}/getUserPage`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

  updatePassword(data) {
    return axios.post(`${prefixPath}/updatePassword`, data)
  },
  updateOwnPassword(data) {
    return axios.post(`${prefixPath}/updateOwnPassword`, data)
  },

  lockedUser(data) {
    return axios.post(`${prefixPath}/lockedUser`, data)
  },

  getUserPageByRole(data) {
    return axios.post(`${prefixPath}/getUserPageByRole`, data)
  },

  getUserByAgent() {
    return axios.get(`${prefixPath}/getUserByAgent`)
  },

  getAgentByUserId(userId) {
    return axios.get(`${prefixPath}/getAgentByUserId`, {
      params: {
        userId,
      },
    })
  },

}
