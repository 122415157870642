const ucenter = [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/ucenter/User'),
    meta: {
      layout: 'content',
      resource: 'menu_user_manage',
    },
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/ucenter/Role'),
    meta: {
      layout: 'content',
      resource: 'menu_role_manage',
    },
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/ucenter/Menu'),
    meta: {
      layout: 'content',
      resource: 'menu_menu_manage',
    },
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/ucenter/ResourceCategory'),
    meta: {
      layout: 'content',
      resource: 'menu_resource_category_manage',
    },
  },
  {
    path: '/resource',
    name: 'resource',
    component: () => import('@/views/ucenter/Resource'),
    meta: {
      layout: 'content',
      resource: 'menu_resource_manage',
    },
  },
  {
    path: '/member/level',
    name: 'memberLevel',
    component: () => import('@/views/ucenter/MemberLevel'),
    meta: {
      layout: 'content',
      resource: 'menu_level_manage',
    },
  },
]

export default ucenter
