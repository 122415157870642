const settings = [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings'),
    meta: {
      layout: 'content',
      resource: 'menu_system_set',
    },
  },

]

export default settings
