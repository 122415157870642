const market = [
  {
    path: '/market/notic',
    name: 'notic',
    component: () => import('@/views/market/notic'),
    meta: {
      layout: 'content',
      resource: 'menu_market_notic',
    },
  },
  {
    path: '/market/banner',
    name: 'banner',
    component: () => import('@/views/market/banner'),
    meta: {
      layout: 'content',
      resource: 'menu_market_banner',
    },
  },
  {
    path: '/market/promote',
    name: 'promote',
    component: () => import('@/views/market/promote'),
    meta: {
      layout: 'content',
      resource: 'menu_market_promote',
    },
  },
]

export default market
