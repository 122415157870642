const analysis = [
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/analysis/Analysis'),
    meta: {
      layout: 'content',
      resource: 'menu_analysis',
    },
  },
]

export default analysis
