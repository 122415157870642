const product = [
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product'),
    meta: {
      layout: 'content',
      resource: 'menu_product',
    },
  },
  {
    path: '/productAttr',
    name: 'productAttr',
    component: () => import('@/views/product/productAttr'),
    meta: {
      layout: 'content',
      resource: 'menu_product_attr',
    },
  },
  {
    path: '/productCategory',
    name: 'productCategory',
    component: () => import('@/views/product/category'),
    meta: {
      layout: 'content',
      resource: 'menu_product_category',
    },
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('@/views/product/warehouse'),
    meta: {
      layout: 'content',
      resource: 'menu_warehouse',
    },
  },
  {
    path: '/stockType',
    name: 'stockType',
    component: () => import('@/views/product/stockType'),
    meta: {
      layout: 'content',
      resource: 'menu_stock_type',
    },
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('@/views/product/stock'),
    meta: {
      layout: 'content',
      resource: 'menu_stock',
    },
  },
  {
    path: '/stockLog',
    name: 'stockLog',
    component: () => import('@/views/product/stockLog'),
    meta: {
      layout: 'content',
      resource: 'menu_stock_log',
    },
  },
  {
    path: '/materialBill',
    name: 'materialBill',
    component: () => import('@/views/product/materialBill'),
    meta: {
      layout: 'content',
      resource: 'menu_material_bill',
    },
  },

]

export default product
