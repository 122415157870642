// axios
import axios from 'axios'
import Vue from 'vue'
import AuthApi from '@/api/auth'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: '/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

let isRefreshing = false // 标记是否正在刷新 token
let requests = [] // 存储待重发请求的数组

axiosIns.interceptors.request.use(
  config => {
    if (config.url.startsWith('/api')) {
      // Do something before request is sent
      const userInfo = localStorage.getItem('userInfo')
      // eslint-disable-next-line no-param-reassign
      if (userInfo) {
        const User = JSON.parse(userInfo)

        config.headers.Authorization = `Bearer ${User.accessToken}`
      }

      // const accessToken = localStorage.getItem('accessToken')
      // if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  data => {
    if(data.config.url.startsWith('/api/order/excel/export')) {
      return data
    }

    if (data.config.url.startsWith('/api')) {
      if (data.data.code !== 0) {
        let errMsg = data.data.msg || '请求发生异常'
        const errData = data.data.data
        if (errData !== null && errData !== undefined && errData) {
          errMsg = `${errMsg}：${JSON.stringify(errData)}`
        }
        Vue.prototype.$toast.error(errMsg)

        return Promise.reject()
      }
    }

    return data
  },
  error => {
    if(error.config.url.startsWith('/api/order/excel/export')) {
      Vue.prototype.$toast.warning('无符合的数据，导出失败')

      return Promise.reject(error)
    }
    if (error.response.status === 401 && !error.config.url.includes('/api/boss/ucenter/oauth/refreshToken')) {
      // 无感刷新
      const { config } = error
      if (!isRefreshing) {
        isRefreshing = true
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
          const user = JSON.parse(userInfo)

          return AuthApi.refreshToken(user.refreshToken).then(res => {
            const { data } = res.data
            localStorage.setItem('userInfo', JSON.stringify(data))
            config.headers.Authorization = `Bearer ${data.accessToken}`

            // token 刷新后将数组的方法重新执行
            requests.forEach(cb => cb(data.accessToken))
            requests = [] // 重新请求完清空

            return axiosIns(config)
          }).catch(err => {
            localStorage.removeItem('userInfo')

            // Remove userData & Ability from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem('userAbility')
            router.push({ name: 'auth-login' })

            return Promise.reject(err)
          }).finally(() => {
            isRefreshing = false
          })
        }
      } else {
        // 返回未执行 resolve 的 Promise
        return new Promise(resolve => {
          // 用函数形式将 resolve 存入，等待刷新后再执行
          requests.push(token => {
            config.headers.Authorization = `Bearer ${token}`
            resolve(axiosIns(config))
          })
        })
      }
    } else if (error.response.status === 404) {
      Vue.prototype.$toast.error('资源不存在')
    } else if (error.response.status === 403) {
      // Vue.prototype.$toast.error('无访问权限')
      router.push({ name: 'misc-not-authorized' })
    } else {
      Vue.prototype.$toast.error('请求发生异常')
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
