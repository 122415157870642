import jwt from 'jsonwebtoken'
import mock from '@/@fake-db/mock'

const data = {
  users: [
    {
      id: 1,
      fullName: 'RILI.PRO',
      username: 'admin',
      password: 'mSZY@Wo*X8doNpTU',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1.png'),
      email: 'admin@rili.pro',

      role: 'admin',
      ability: [

        {
          action: 'read',
          subject: 'menu_analysis',
        },
        {
          action: 'read',
          subject: 'menu_article',
        },

        {
          action: 'article_add',
          subject: 'article',
        },
        {
          action: 'article_list',
          subject: 'article',
        },
        {
          action: 'read',
          subject: 'Public',
        },
      ],
    },
  ],
}

// ! These two secrets shall be in .env file and not in any other file
// const jwtConfig = {
//   secret: 'db3ddc0c-10fc-4a24-a1fa-c1c2b76a936d',
//   refreshTokenSecret: '8dd5081f-741d-43a6-ad5d-9d6e9eee74be',
// }

// mock.onPost('/auth/login').reply(request => {
//   const { email, password } = JSON.parse(request.data)

//   let error = {
//     email: ['Something went wrong'],
//   }

//   const user = data.users.find(u => u.email === email && u.password === password)

//   if (user) {
//     try {
//       const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret)
//       const response = {
//         data: accessToken,
//         code: 0,
//       }

//       return [200, response]
//     } catch (e) {
//       error = e
//     }
//   } else {
//     error = {
//       email: ['Email or Password is Invalid'],
//     }
//   }

//   return [400, { error }]
// })

// mock.onGet('/auth/me').reply(() => {
//   const user = JSON.parse(JSON.stringify(data.users.find(u => u.id === 1)))

//   return [200, {
//     data: user,
//     code: 0,
//   }]
// })
