const agent = [
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/agent/agent'),
    meta: {
      layout: 'content',
      resource: 'menu_agent',
    },
  },
]

export default agent
