import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import VSnackbar from 'vuetify/lib/components/VSnackbar'
import VBtn from 'vuetify/lib/components/VBtn/VBtn'
import VIcon from 'vuetify/lib/components/VIcon'

import VuetifyToast from 'vuetify-toast-snackbar-ng'

import {
  mdiClose,
} from '@mdi/js'
import preset from '@/@core/preset/preset'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'top', // default: bottom
  color: 'info', // default
  icon: '',
  iconColor: '', // default
  classes: [
    'body-2',
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false,
  showClose: false,
  closeText: '', // default
  closeIcon: mdiClose, // default
  closeColor: '', // default
  slot: [], // default
  shorts: {
    success: {
      color: 'success',
      icon: '',
    },
  },
  property: '$toast', // default
})

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
