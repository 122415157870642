import {
  mdiAccountSupervisorOutline,
  mdiHomeOutline,
  mdiPackageVariantClosed,
  mdiFileDocumentOutline,
  mdiWalletGiftcard,
  mdiCardAccountDetailsOutline,
} from '@mdi/js'

export default [
  {
    title: '首页',
    icon: mdiHomeOutline,
    to: 'analysis',
    action: 'read',
    resource: 'menu_analysis',
  },
  {
    title: '商品',
    icon: mdiPackageVariantClosed,
    children: [
      {
        title: '商品管理',
        to: 'product',
        resource: 'menu_product',
        action: 'read',
      },
      {
        title: '出入库管理',
        to: 'stock',
        resource: 'menu_stock',
        action: 'read',
      },
      {
        title: '库存管理',
        to: 'stockLog',
        resource: 'menu_stock_log',
        action: 'read',
      },
      {
        title: '领料管理',
        to: 'materialBill',
        resource: 'menu_material_bill',
        action: 'read',
      },
      {
        title: '分类设置',
        to: 'productCategory',
        resource: 'menu_product_category',
        action: 'read',
      },
      {
        title: '导航设置',
        to: 'productAttr',
        resource: 'menu_product_attr',
        action: 'read',
      },
      {
        title: '仓库管理',
        to: 'warehouse',
        resource: 'menu_warehouse',
        action: 'read',
      },
      {
        title: '出入库类型',
        to: 'stockType',
        resource: 'menu_stock_type',
        action: 'read',
      },
    ],
  },
  {
    title: '订单',
    icon: mdiFileDocumentOutline,
    to: 'order',
    action: 'read',
    resource: 'menu_order',
  },
  {
    title: '营销',
    icon: mdiWalletGiftcard,
    children: [
      {
        title: 'Banner',
        to: 'banner',
        resource: 'menu_market_banner',
        action: 'read',
      },
      {
        title: '促销管理',
        to: 'promote',
        resource: 'menu_market_promote',
        action: 'read',
      },
      {
        title: '系统公告',
        to: 'notic',
        resource: 'menu_market_notic',
        action: 'read',
      },
      {
        title: '会员等级',
        to: 'memberLevel',
        resource: 'menu_level_manage',
        action: 'read',
      },
    ],
  },
  {
    title: '代理商管理',
    icon: mdiCardAccountDetailsOutline,
    to: 'agent',
    action: 'read',
    resource: 'menu_agent',
  },
  {
    title: '用户',
    icon: mdiAccountSupervisorOutline,
    children: [
      {
        title: '用户管理',
        to: 'user',
        resource: 'menu_user_manage',
        action: 'read',
      },
      {
        title: '角色管理',
        to: 'role',
        resource: 'menu_role_manage',
        action: 'read',
      },
      {
        title: '资源分类管理',
        to: 'category',
        resource: 'menu_resource_category_manage',
        action: 'read',
      },
      {
        title: '资源管理',
        to: 'resource',
        resource: 'menu_resource_manage',
        action: 'read',
      },
      {
        title: '菜单管理',
        to: 'menu',
        resource: 'menu_menu_manage',
        action: 'read',
      },
    ],
  },

]
