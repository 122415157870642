import '@/@fake-db/db'

// import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn'
import App from './App'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(CKEditor)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
