<template>
  <div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            color="primary"
            class="v-avatar-light-bg primary--text"
            v-on="on"
          >
            <v-img
              v-if="userData.avatar"
              :src="userData.avatar"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar
              size="40px"
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <v-img
                v-if="userData.avatar"
                src="@/assets/images/avatars/1.png"
              ></v-img>
              <v-icon
                v-else
                color="primary"
                size="28"
              >
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              {{ userData.fullName || userData.username }}
            </span>
            <small class="text--disabled text-capitalize">{{ userData.role }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <v-list-item @click="updatePasswordDialog = true">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiUpdate }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>修改密码</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Logout -->
        <v-list-item @click="logoutUser">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>退出登录</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="updatePasswordDialog"
      width="500"
    >
      <v-card>
        <v-card-title>修改密码</v-card-title>
        <v-card-text>
          <v-form ref="passwordForm">
            <v-row style="height: 60px">
              <v-col
                cols="12"
                md="3"
              >
                <label for="password">密码</label>
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  id="password"
                  v-model="editedItem.password"
                  :rules="rules.password"
                  required
                  type="password"
                  label="请输入密码"
                  outlined
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  outlined
                  color="info"
                  @click="openRandomPassDialog()"
                >
                  生成随机密码
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="repassword">确认密码</label>
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  id="repassword"
                  v-model="editedItem.repassword"
                  required
                  type="password"
                  :rules="rules.repass"
                  label="请再次输入密码"
                  outlined
                  clearable
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="normal"
            text
            @click="updatePasswordDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="modifyPassword()"
          >
            确认修改
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="randomPassDialog"
      width="400"
    >
      <v-card>
        <v-card-title>生成随机密码</v-card-title>
        <v-card-text>
          <v-form ref="randomPassForm">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="randomPass">随机密码</label>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="randomPass"
                  v-model="randomPass"
                  required
                  :rules="rules.required"
                  outlined
                  clearable
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="normal"
            text
            @click="mkdirRandomPass(8)"
          >
            生成
          </v-btn>
          <v-btn
            color="info"
            text
            @click="useRandomPass()"
          >
            应用
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLogoutVariant,
  mdiUpdate,
} from '@mdi/js'
import UserApi from '@/api/ucnter/user'

export default {
  data() {
    return {

      userData: JSON.parse(localStorage.getItem('userData')),
      updatePasswordDialog: false,
      editedItem: {},
      randomPassDialog: false,
      randomPass: '',
      rules: {
        phone: [v => !!v || '该字段不能为空', v => /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(v) || '不符合手机号码格式'],
        required: [v => !!v || '该字段不能为空'],
        password: [v => !!v || '密码不能为空', v => (v && v.length >= 8) || '密码长度不能少于8位'],
        repass: [v => !!v || '密码不能为空', v => this.editedItem.password === v || '两次密码输入不一致'],
      },
      charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$',
      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
        mdiUpdate,
      },
    }
  },
  methods: {
    logoutUser() {
      this.$router.push({ name: 'auth-logout' })
    },
    openUpdatePasswordDialog() {
      this.$nextTick(() => {
        const { passwordForm } = this.$refs
        if (passwordForm) {
          passwordForm.reset()
        }
      })
      this.updatePasswordDialog = true
    },
    openRandomPassDialog() {
      this.$nextTick(() => {
        const { randomPassForm } = this.$refs
        if (randomPassForm) {
          randomPassForm.reset()
        }
      })
      this.randomPassDialog = true
    },
    mkdirRandomPass(length) {
      let password = ''
      for (let i = 0; i < length; i++) {
        password += this.charset.charAt(Math.floor(Math.random() * this.charset.length))
      }
      this.randomPass = password
    },
    useRandomPass() {
      this.editedItem.password = this.randomPass
      this.editedItem.repassword = this.randomPass
      this.randomPassDialog = false
    },
    modifyPassword() {
      const isFormValid = this.$refs.passwordForm.validate()
      if (!isFormValid) return
      this.editedItem.id = this.userData.id
      UserApi.updateOwnPassword(this.editedItem).then(res => {
        this.$toast.success('修改成功')
        this.editedItem.id = ''
        this.editedItem.password = ''
        this.editedItem.repassword = ''
        this.randomPass = ''
        this.updatePasswordDialog = false
      })
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
