import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/oauth'
export default {

  login(username, password, companyId, authType) {
    return axios.post(`${prefixPath}/login`, qs.stringify({
      username, password, companyId, authType,
    }))
  },

  // login(email, password) {
  //   return axios.post('/auth/login', { email, password })
  // },
  // me() {
  //   return axios.get('/auth/me')
  // },
  refreshToken(refreshToken) {
    return axios.post(`${prefixPath}/refreshToken`, qs.stringify({ refreshToken }))
  },
  logout(accessToken) {
    return axios.post(`${prefixPath}/logout`, qs.stringify({ accessToken }))
  },

  me() {
    return axios.post(`${prefixPath}/me`)
  },

}
