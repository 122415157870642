import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/plugins/acl/routeProtection'

import analysis from '@/router/analysis'
import data from '@/router/data'
import user from '@/router/user'
import settings from '@/router/settings'
import product from '@/router/product'
import ucenter from '@/router/ucenter'
import market from '@/router/market'
import order from '@/router/order'
import agent from '@/router/agent'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',

    // redirect: () => ({ name: 'analysis' }),
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      if (userRole !== null) return { name: 'analysis' }

      // if (userRole === 'admin') return { name: 'dashboard-crm' }
      // if (userRole === 'client') return { name: 'page-access-control' }

      if (userData === null) {
        return { name: 'auth-login', query: to.query }
      }

      return { name: 'error-403', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/error/Error403'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import('@/views/Logout'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/printer/Print'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  ...analysis,
  ...data,
  ...user,
  ...settings,
  ...ucenter,
  ...product,
  ...market,
  ...order,
  ...agent,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('userInfo') && localStorage.getItem('userAbility')

  // if (isLoggedIn && to.meta.resource === 'Public') {
  //   return next()
  // }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'error-403' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
