const order = [
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order'),
    meta: {
      layout: 'content',
      resource: 'menu_order',
    },
  },
]

export default order
