<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!--          <app-bar-user-menu></app-bar-user-menu>-->
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <!-- <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://rili.pro"
          class="text-decoration-none"
        >工厂商城管理后台</a>, All rights Reserved</span> -->
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav'
import navMenuItems from '@/navigation/vertical'

// App Bar Components
import AppBarUserMenu from '@/components/app-bar/AppBarUserMenu'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    // const $vuetify = getVuetify()
    return {
      navMenuItems,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

</style>
